import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { class: "my-auto justify-self-end ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_component_Listbox, {
    modelValue: _ctx.listboxValue,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.listboxValue) = $event))
  }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ListboxButton, {
          class: "overflow-hidden relative w-full shadow-none rounded-full transition-colors p-1 hover:bg-gray-50",
          onClick: _ctx.onListboxButtonClicked,
          onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.buttonFocused = true)),
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.buttonFocused = false)),
          onKeydown: [
            _withKeys(_ctx.onListboxButtonClicked, ["enter"]),
            _withKeys(_ctx.onListboxButtonClicked, ["space"])
          ]
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_Icon, {
                src: _ctx.IconSource.Hamburger,
                class: "h-[26px] w-auto"
              }, null, 8, ["src"])
            ])
          ]),
          _: 1
        }, 8, ["onClick", "onKeydown"]),
        _createVNode(_Transition, {
          "enter-active-class": "transition duration-200 ease-out",
          "enter-from-class": "transform scale-95 opacity-0",
          "enter-to-class": "transform scale-100 opacity-100",
          "leave-active-class": "transition duration-100 ease-out",
          "leave-from-class": "transform scale-100 opacity-100",
          "leave-to-class": "transform scale-95 opacity-0"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass([[_ctx.dropdownHeightClass], "absolute -mt-1 top-full right-0 bg-white border z-30 rounded overflow-y-auto whitespace-nowrap"])
            }, [
              _createVNode(_component_ListboxOptions, {
                static: "",
                class: "outline-none",
                onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.expandListbox = false), ["stop"]), ["esc"]))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, index) => {
                    return (_openBlock(), _createBlock(_component_ListboxOption, {
                      key: item[_ctx.itemValue],
                      value: item
                    }, {
                      default: _withCtx(({ active }) => [
                        _createElementVNode("button", {
                          type: "button",
                          class: _normalizeClass([[_ctx.itemSelection[index] ? '' : 'hover:bg-gray-100', active ? 'bg-gray-100' : ''], "p-3 h-full text-left w-full cursor-pointer flex"])
                        }, [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(item[_ctx.itemText]), 1),
                          _createElementVNode("span", _hoisted_3, [
                            _createVNode(_component_CustomCheckbox, {
                              checked: _ctx.itemSelection[index],
                              "onUpdate:checked": ($event: any) => (_ctx.listboxValue = item)
                            }, null, 8, ["checked", "onUpdate:checked"])
                          ])
                        ], 2)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              })
            ], 2), [
              [_vShow, _ctx.expandListbox]
            ])
          ]),
          _: 1
        })
      ])), [
        [_directive_click_outside, _ctx.onClickOutside]
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}