const defaultTheme = require('tailwindcss/defaultTheme');
const plugin = require('tailwindcss/plugin');

const colors = require(`./public/solutions/${process.env.VUE_APP_SOLUTION_PATH}/colors`);

function filterDefault(values) {
    return Object.fromEntries(Object.entries(values).filter(([key]) => key !== 'DEFAULT'));
}

module.exports = {
    mode: 'jit',
    purge: ['./public/index.html', './src/**/*.vue', './node_modules/@makeabledk/**/*.vue'],
    theme: {
        extend: {
            transitionDuration: {
                2000: '2000ms',
            },
            transitionProperty: {
                width: 'width',
                'margin-left': 'margin-left',
                height: 'height',
                borderRadius: 'border-radius',
            },
            willChange: {
                height: 'height',
            },
            fontFamily: {
                sans: ['Noto Sans', ...defaultTheme.fontFamily.sans],
            },
            spacing: {
                2.5: '0.625rem',
                2.75: '0.6875rem',
                6.5: '1.625rem',
                16.5: '4.125rem',
                17: '4.25rem',
                19: '4.75rem',
                21: '5.25rem',
                42: '10.5rem',
                46: '12.5rem',
                76: '17rem',
                84: '21rem',
                88: '22rem',
                92: '23rem',
                100: '25rem',
                112: '28rem',
                120: '30rem',
                128: '32rem',
                160: '40rem',
                228: '57rem',
                256: '64rem',
                280: '70rem',
                288: '72rem',
                dashboard: '1500px',
            },
            minHeight: (theme) => ({
                ...theme('spacing'),
            }),

            minWidth: (theme) => ({
                ...theme('spacing'),
            }),

            maxWidth: (theme) => ({
                ...theme('spacing'),
                screen: '100vw',
            }),

            maxHeight: (theme) => ({
                ...theme('spacing'),
            }),
            backgroundOpacity: {
                35: '0.35',
            },
            colors: {
                ...colors,
                gray: {
                    ...defaultTheme.colors.gray,
                    50: '#f9fafb',
                },
                green: {
                    ...defaultTheme.colors.green,
                    200: '#91C391',
                    600: '#CDDDDD',
                },
                'dark-gray': {
                    100: 'rgba(245,246,250,0.63)',
                    200: '#F5F6FA',
                    600: 'rgba(22,17,33,0.8)',
                    800: '#161121',
                },
                red: {
                    ...defaultTheme.colors.red,
                    400: '#F5492B',
                },
                sky: {
                    50: '#f0f9ff',
                    100: '#e0f2fe',
                    200: '#bae6fd',
                    300: '#7dd3fc',
                    400: '#38bdf8',
                    500: '#0ea5e9',
                    600: '#0284c7',
                    700: '#0369a1',
                    800: '#075985',
                    900: '#0c4a6e',
                    950: '#082f49',
                },
            },
            fontSize: {
                xs: ['0.6875rem', '1rem'],
                sm: ['0.8125rem', '1.1875rem'],
                base: ['1rem', '1.5rem'],
                lg: ['1.125rem', '1.625rem'],
                larger: ['1.25rem', '1.75rem'],
                xl: ['1.5rem', '2.25rem'],
                '2xl': ['1.75rem', '2.625rem'],
                '3xl': ['2rem', '2.875rem'],
            },
            screens: {
                print: {
                    raw: 'print',
                },
            },
        },

        container: () => ({
            center: true,
        }),
    },
    variants: {
        opacity: ['responsive', 'hover', 'focus', 'disabled'],
        extend: {
            padding: ['first', 'last'],
            borderRadius: ['first', 'last'],
            margin: ['first', 'last'],
            borderWidth: ['first', 'last'],
            backgroundColor: ['odd', 'even'],
        },
    },
    plugins: [
        plugin(({ addUtilities, theme, e }) => {
            const themeColors = theme('colors');
            const accentColors = Object.keys(themeColors).reduce((acc, color) => {
                const colorShades = themeColors[color];
                if (typeof colorShades === 'string') {
                    acc[`.accent-${e(color)}`] = { accentColor: colorShades };
                } else {
                    Object.keys(colorShades).forEach((shade) => {
                        acc[`.accent-${e(color)}-${e(shade)}`] = { accentColor: colorShades[shade] };
                    });
                }
                return acc;
            }, {});
            addUtilities(accentColors, ['responsive', 'hover']);
        }),
        // https://github.com/jamiebuilds/tailwindcss-animate
        plugin(
            ({ addUtilities, matchUtilities, theme }) => {
                addUtilities({
                    '@keyframes enter': theme('keyframes.enter'),
                    '@keyframes exit': theme('keyframes.exit'),
                    '.animate-in': {
                        animationName: 'enter',
                        animationDuration: theme('animationDuration.DEFAULT'),
                        '--tw-enter-opacity': 'initial',
                        '--tw-enter-scale': 'initial',
                        '--tw-enter-rotate': 'initial',
                        '--tw-enter-translate-x': 'initial',
                        '--tw-enter-translate-y': 'initial',
                    },
                    '.animate-out': {
                        animationName: 'exit',
                        animationDuration: theme('animationDuration.DEFAULT'),
                        '--tw-exit-opacity': 'initial',
                        '--tw-exit-scale': 'initial',
                        '--tw-exit-rotate': 'initial',
                        '--tw-exit-translate-x': 'initial',
                        '--tw-exit-translate-y': 'initial',
                    },
                });

                matchUtilities(
                    {
                        'fade-in': (value) => ({ '--tw-enter-opacity': value }),
                        'fade-out': (value) => ({ '--tw-exit-opacity': value }),
                    },
                    { values: theme('animationOpacity') }
                );

                matchUtilities(
                    {
                        'zoom-in': (value) => ({ '--tw-enter-scale': value }),
                        'zoom-out': (value) => ({ '--tw-exit-scale': value }),
                    },
                    { values: theme('animationScale') }
                );

                matchUtilities(
                    {
                        'spin-in': (value) => ({ '--tw-enter-rotate': value }),
                        'spin-out': (value) => ({ '--tw-exit-rotate': value }),
                    },
                    { values: theme('animationRotate') }
                );

                matchUtilities(
                    {
                        'slide-in-from-top': (value) => ({
                            '--tw-enter-translate-y': `-${value}`,
                        }),
                        'slide-in-from-bottom': (value) => ({
                            '--tw-enter-translate-y': value,
                        }),
                        'slide-in-from-left': (value) => ({
                            '--tw-enter-translate-x': `-${value}`,
                        }),
                        'slide-in-from-right': (value) => ({
                            '--tw-enter-translate-x': value,
                        }),
                        'slide-out-to-top': (value) => ({
                            '--tw-exit-translate-y': `-${value}`,
                        }),
                        'slide-out-to-bottom': (value) => ({
                            '--tw-exit-translate-y': value,
                        }),
                        'slide-out-to-left': (value) => ({
                            '--tw-exit-translate-x': `-${value}`,
                        }),
                        'slide-out-to-right': (value) => ({
                            '--tw-exit-translate-x': value,
                        }),
                    },
                    { values: theme('animationTranslate') }
                );

                matchUtilities({ duration: (value) => ({ animationDuration: value }) }, { values: filterDefault(theme('animationDuration')) });

                matchUtilities({ delay: (value) => ({ animationDelay: value }) }, { values: theme('animationDelay') });

                matchUtilities({ ease: (value) => ({ animationTimingFunction: value }) }, { values: filterDefault(theme('animationTimingFunction')) });

                addUtilities({
                    '.running': { animationPlayState: 'running' },
                    '.paused': { animationPlayState: 'paused' },
                });

                matchUtilities({ 'fill-mode': (value) => ({ animationFillMode: value }) }, { values: theme('animationFillMode') });

                matchUtilities({ direction: (value) => ({ animationDirection: value }) }, { values: theme('animationDirection') });

                matchUtilities({ repeat: (value) => ({ animationIterationCount: value }) }, { values: theme('animationRepeat') });
            },
            {
                theme: {
                    extend: {
                        animationDelay: (theme) => ({
                            ...theme('transitionDelay'),
                        }),
                        animationDuration: (theme) => ({
                            0: '0ms',
                            ...theme('transitionDuration'),
                        }),
                        animationTimingFunction: (theme) => ({
                            ...theme('transitionTimingFunction'),
                        }),
                        animationFillMode: {
                            none: 'none',
                            forwards: 'forwards',
                            backwards: 'backwards',
                            both: 'both',
                        },
                        animationDirection: {
                            normal: 'normal',
                            reverse: 'reverse',
                            alternate: 'alternate',
                            'alternate-reverse': 'alternate-reverse',
                        },
                        animationOpacity: (theme) => ({
                            DEFAULT: 0,
                            ...theme('opacity'),
                        }),
                        animationTranslate: (theme) => ({
                            DEFAULT: '100%',
                            ...theme('translate'),
                        }),
                        animationScale: (theme) => ({
                            DEFAULT: 0,
                            ...theme('scale'),
                        }),
                        animationRotate: (theme) => ({
                            DEFAULT: '30deg',
                            ...theme('rotate'),
                        }),
                        animationRepeat: {
                            0: '0',
                            1: '1',
                            infinite: 'infinite',
                        },
                        keyframes: {
                            enter: {
                                from: {
                                    opacity: 'var(--tw-enter-opacity, 1)',
                                    transform:
                                        'translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0))',
                                },
                            },
                            exit: {
                                to: {
                                    opacity: 'var(--tw-exit-opacity, 1)',
                                    transform:
                                        'translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0))',
                                },
                            },
                        },
                    },
                },
            }
        ),
    ],
    important: true,
};
