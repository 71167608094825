<template>
    <MainViewCard>
        <div class="divide-y divide-gray-200 space-y-4 px-4 py-4" :class="{ 'expanded-barn': isExpanded }" style="scroll-margin-top: 120px">
            <div class="flex justify-between items-center space-x-3">
                <div class="flex items-center space-x-3">
                    <div>
                        <Icon class="h-6" :src="IconSource.Location"></Icon>
                    </div>
                    <div>
                        <div class="font-bold text-lg">{{ barn.headers.barn_name }}</div>
                        <div class="text-sm text-gray-500">
                            {{ barn.rows?.length || 0 }} {{ barn.rows?.length === 1 ? $t('views.monitoring.main.headings.section') : $t('views.monitoring.main.headings.sectionPlural') }}
                        </div>
                    </div>
                </div>
                <div v-if="barn.rows && barn.rows.length">
                    <button class="text-sky-500 hover:text-sky-700 transition-colors p-2 -mr-2" @click.prevent="toggleExpandBarn(barn.headers.barn_id)">
                        {{ isExpanded ? $t('views.monitoring.main.buttons.hideSections') : $t('views.monitoring.main.buttons.viewSections') }}
                    </button>
                </div>
            </div>
            <div
                v-if="
                    barn.rows &&
                    barn.rows.length &&
                    (barn.headers.pigs.is_visible || barn.headers.avg_weight.is_visible || barn.headers.mortality.is_visible || barn.headers.water_consumption.is_visible || barn.headers.check_in.is_visible)
                "
                class="flex items-stretch space-x-3 pt-4"
            >
                <div v-if="barn.headers.pigs.is_visible" class="flex flex-1 border border-gray-200 rounded-lg p-2.5">
                    <div class="flex items-center space-x-3">
                        <Icon :src="barn.headers.pigs.icon_id" class="w-10 h-10"></Icon>
                        <div>
                            <div class="font-bold">{{ barn.headers.pigs.number || '' }}</div>
                            <div class="text-gray-400 text-sm">{{ barn.headers.pigs.name }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="barn.headers.avg_weight.is_visible" class="flex flex-1 border border-gray-200 rounded-lg p-2.5">
                    <div class="flex items-center space-x-3">
                        <Icon :src="barn.headers.avg_weight.icon_id" class="w-10 h-10"></Icon>
                        <div>
                            <div class="font-bold">{{ barn.headers.avg_weight.number || '' }}</div>
                            <div class="text-gray-400 text-sm">{{ barn.headers.avg_weight.name }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="barn.headers.mortality.is_visible" class="flex flex-1 border border-gray-200 rounded-lg p-2.5">
                    <div class="flex items-center space-x-3">
                        <Icon :src="barn.headers.mortality.icon_id" class="w-10 h-10"></Icon>
                        <div>
                            <div class="font-bold">{{ barn.headers.mortality.number }}</div>
                            <div class="text-gray-400 text-sm">{{ barn.headers.mortality.name }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="barn.headers.water_consumption.is_visible" class="flex flex-1 border border-gray-200 rounded-lg p-2.5">
                    <div class="flex items-center space-x-3">
                        <Icon :src="barn.headers.water_consumption.icon_id" class="w-10 h-10"></Icon>
                        <div>
                            <div class="font-medium text-red-400">
                                <span v-if="barn.headers.water_consumption.alarm_severity === 3" class="text-red-400">
                                    {{ $t('views.monitoring.main.tables.alarmStatus.alarm') }}
                                </span>
                                <span v-else-if="barn.headers.water_consumption.alarm_severity === 2" class="text-yellow-400">
                                    {{ $t('views.monitoring.main.tables.alarmStatus.attention') }}
                                </span>
                                <span v-else class="text-green-400">
                                    {{ $t('views.monitoring.main.tables.alarmStatus.OK') }}
                                </span>
                            </div>
                            <div class="text-gray-400 text-sm">{{ barn.headers.water_consumption.name }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="barn.headers.check_in.is_visible" class="flex flex-1 border border-gray-200 rounded-lg p-2.5">
                    <div class="flex items-center space-x-3">
                        <Icon :src="barn.headers.check_in.icon_id" class="w-10 h-10"></Icon>
                        <div>
                            <div class="font-medium" :class="{ 'text-red-400': hasCheckInAlarm }">{{ barn.headers.check_in.max_elapsed_hours }}</div>
                            <div class="text-gray-400 text-sm">{{ barn.headers.check_in.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="text-center font-medium pt-6 pb-2">
                {{ $t('views.monitoring.main.tables.noSectionsText') }}
            </div>
        </div>
        <ExpandCollapseTransition>
            <div v-if="isExpanded">
                <CustomTable :headers="headers" :items="items" :expanded-items="expandedRows" :rounded="false">
                    <template #header_actions>
                        <th class="w-6"></th>
                    </template>
                    <template #_treatments="{ item, getHeaderClass, getWidth, header }">
                        <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                            <TreatmentsModal :treatments="item._treatments" :section-name="item.section_name" :barn-name="barn.headers.barn_name">
                                <template #default="{ open }">
                                    <button class="text-sky-500 hover:text-sky-700 underline transition-colors p-2 -ml-2" style="text-underline-offset: 2px" @click="open">{{ item._treatments.length }}</button>
                                </template>
                            </TreatmentsModal>
                        </td>
                    </template>
                    <template #_waterConsumption="{ item, getHeaderClass, getWidth, header }">
                        <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                            <div>
                                <AlertBadge v-if="item.water_consumption.max_severity_level === 3" type="error" class="flex-shrink-0">
                                    {{ $t('views.monitoring.main.tables.alarmStatus.alarm') }}
                                </AlertBadge>
                                <AlertBadge v-else-if="item.water_consumption.max_severity_level === 2" type="warning" class="flex-shrink-0">
                                    {{ $t('views.monitoring.main.tables.alarmStatus.attention') }}
                                </AlertBadge>
                                <AlertBadge v-else type="success" class="flex-shrink-0">
                                    {{ $t('views.monitoring.main.tables.alarmStatus.OK') }}
                                </AlertBadge>
                            </div>
                        </td>
                    </template>
                    <template #_checkIn="{ item, getHeaderClass, getWidth, header }">
                        <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                            <div class="flex items-center">
                                <Icon v-if="item.check_in.is_red" :src="IconSource.AlarmIcon" class="h-6 w-6 mr-1"></Icon>
                                {{ item.check_in.value }}
                            </div>
                        </td>
                    </template>
                    <template #actions="{ index }">
                        <td class="w-6 flex-shrink-0 my-auto">
                            <span class="flex flex-shrink-0 justify-end">
                                <button type="button" class="p-2 -mr-2" @click="toggleRowExpand(index)">
                                    <Icon class="h-3.5 w-3.5 select-none" :src="expandedRows.includes(index) ? IconSource.Close : IconSource.Right"></Icon>
                                </button>
                            </span>
                        </td>
                    </template>
                    <template #expanded-row="{ item }">
                        <td :colspan="headers.length" class="w-full">
                            <OverviewCharts :section-id="item.section_id" />
                        </td>
                    </template>
                </CustomTable>
            </div>
        </ExpandCollapseTransition>
    </MainViewCard>
</template>

<script lang="ts">
import { computed, defineComponent, ref, inject } from 'vue';
import { MonitoringDataBarn, IconSource } from '@/types';
import CustomTable from '@/components/ui/CustomTable.vue';
import TreatmentsModal from '@/views/dashboard/monitoring/monitoring/overview/TreatmentsModal.vue';
import AlertBadge from '@/components/ui/AlertBadge.vue';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import OverviewCharts from './OverviewCharts.vue';
import Icon from '@/components/icons/Icon.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { MONITORING_STATE } from '../injectionKeys';

export default defineComponent({
    components: { CustomTable, TreatmentsModal, AlertBadge, ExpandCollapseTransition, MainViewCard, OverviewCharts, Icon },
    props: {
        barn: {
            type: Object as () => MonitoringDataBarn,
            required: true,
        },
    },
    setup(props) {
        const headers = ref<any[]>([
            { text: i18n.global.t('views.monitoring.main.tables.headings.name'), value: 'section_name', align: 'left', sortable: true },
            { text: i18n.global.t('views.monitoring.main.tables.headings.pigs'), value: 'number_of_pigs', align: 'left', sortable: true },
            { text: i18n.global.t('views.monitoring.main.tables.headings.weight'), value: 'avg_weight', align: 'left', sortable: true },
            { text: i18n.global.t('views.monitoring.main.tables.headings.mortality'), value: 'mortality', align: 'left', sortable: true },
            { text: i18n.global.t('views.monitoring.main.tables.headings.treatments'), value: '_treatments', align: 'center', sortable: true },
            { text: i18n.global.t('views.monitoring.main.tables.headings.water'), value: '_waterConsumption', align: 'left', sortable: true, headerClass: 'min-w-40' },
            { text: i18n.global.t('views.monitoring.main.tables.headings.checkIn'), value: '_checkIn', align: 'left', sortable: true },
            { text: i18n.global.t('views.monitoring.main.tables.headings.slaugteredAfter'), value: 'slaughtered_after', align: 'left', sortable: true },
            { text: '', value: 'actions', align: 'right', sortable: false },
        ]);

        const { expandedBarns, toggleExpandBarn } = inject(MONITORING_STATE) as any;

        const isExpanded = computed(() => expandedBarns.value.includes(props.barn.headers.barn_id));
        const expandedRows = ref<number[]>([]);

        function toggleRowExpand(index: number) {
            if (expandedRows.value.includes(index)) {
                expandedRows.value = expandedRows.value.filter((i) => i !== index);
            } else {
                expandedRows.value.push(index);
            }
        }

        const items = computed(() => props.barn.rows || []);
        const hasCheckInAlarm = computed(() => props.barn.rows?.some((r) => r.check_in.is_red) || false);

        return {
            headers,
            isExpanded,
            expandedRows,
            IconSource,
            toggleExpandBarn,
            toggleRowExpand,
            items,
            hasCheckInAlarm,
        };
    },
});
</script>
