import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-5" }
const _hoisted_2 = { class: "flex items-center justify-between space-x-4" }
const _hoisted_3 = { class: "flex items-center space-x-3" }
const _hoisted_4 = { class: "font-bold text-lg" }
const _hoisted_5 = { class: "text-sm text-gray-500" }
const _hoisted_6 = { class: "pr-1" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "mt-5 space-y-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_OverviewTable = _resolveComponent("OverviewTable")!
  const _component_ExpandCollapseTransition = _resolveComponent("ExpandCollapseTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.populatedItems, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.farm_id,
        class: _normalizeClass(["border border-gray-300 rounded-lg px-4 py-5", { 'expanded-farm': _ctx.isExpanded(item.farm_id) }]),
        style: {"scroll-margin-top":"120px"}
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createVNode(_component_Icon, {
                class: "h-6",
                src: _ctx.IconSource.FarmsPrimary
              }, null, 8, ["src"])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(item.farm_name), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(item.barns.length) + " " + _toDisplayString(item.barns.length === 1 ? _ctx.$t('views.monitoring.main.headings.barn') : _ctx.$t('views.monitoring.main.headings.barnPlural')) + ", " + _toDisplayString(item.sectionCount) + " " + _toDisplayString(item.sectionCount === 1 ? _ctx.$t('views.monitoring.main.headings.section') : _ctx.$t('views.monitoring.main.headings.sectionPlural')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              type: "button",
              class: "p-3 flex items-center justify-center rounded-full transition-colors hover:bg-gray-200/70 duration-200",
              onClick: ($event: any) => (_ctx.toggleExpandFarm(item.farm_id))
            }, [
              _createVNode(_component_Icon, {
                class: _normalizeClass(["h-4 w-4 transform transition-transform rotate-90 cursor-pointer", { 'rotate-[270deg]': _ctx.isExpanded(item.farm_id) }]),
                src: _ctx.IconSource.Right
              }, null, 8, ["class", "src"])
            ], 8, _hoisted_7)
          ])
        ]),
        _createVNode(_component_ExpandCollapseTransition, null, {
          default: _withCtx(() => [
            (_ctx.isExpanded(item.farm_id))
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.barns, (barn) => {
                    return (_openBlock(), _createBlock(_component_OverviewTable, {
                      key: barn.headers.barn_id,
                      barn: barn
                    }, null, 8, ["barn"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024)
      ], 2))
    }), 128))
  ]))
}