<template>
    <div class="flex justify-end mb-8 min-h-[50px]">
        <transition name="fade" appear>
            <div v-if="assessmentCategory">
                <TooltipProvider :delay-duration="200">
                    <Tooltip>
                        <TooltipTrigger as-child>
                            <RouterLink :to="{ name: 'DataQuality' }" class="inline-flex items-center gap-x-3.5 bg-white shadow-md px-5 py-3 rounded-xl">
                                <div class="text-lg font-medium">{{ $t('views.overview.headings.dataQuality') }}</div>
                                <DataQualityBarStatusBar :quality-assessment-category="assessmentCategory" />
                            </RouterLink>
                        </TooltipTrigger>
                        <TooltipContent class="font-normal" :collision-padding="12">
                            <p v-html="sanitize(tooltip, { allowedAttributes: { span: ['class'] } })" />
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import sanitize from 'sanitize-html';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import DataQualityBarStatusBar from '@/components/common/dataQuality/DataQualityStatusBar.vue';
import { i18n } from '@/plugins/internationalization/i18n';

store.dispatch(ActionType.GetDataQualityCategories);
useRefetchDataOnDashboardStateChanged(ActionType.GetDataQualityAssessment, { watchSites: true });

const assessmentCategory = computed(() => {
    if (!store.state.dataQualityAssessment || !store.state.dataQualityCategories) {
        return null;
    }

    return store.state.dataQualityCategories.find((c) => c.id === store.state.dataQualityAssessment?.quality_assessment_id);
});
const tooltip = computed(() => i18n.global.t('views.overview.tooltips.dataQuality').replace('$ASSESSMENT', `<span class="font-semibold">${assessmentCategory.value?.name}</span>`));
</script>
