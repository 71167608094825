<template>
    <div class="space-y-5">
        <div v-for="item in items" :key="item.farm_id" class="border border-gray-300 rounded-lg px-4 py-5" :class="{ 'expanded-farm': isExpanded(item.farm_id) }" style="scroll-margin-top: 120px">
            <div class="flex items-center justify-between space-x-4">
                <div class="flex items-center space-x-3">
                    <div>
                        <Icon class="h-6" :src="IconSource.FarmsPrimary"></Icon>
                    </div>
                    <div>
                        <div class="font-bold text-lg">{{ item.farm_name }}</div>
                        <div class="text-sm text-gray-500">
                            {{ item.barns.length }} {{ item.barns.length === 1 ? $t('views.monitoring.main.headings.barn') : $t('views.monitoring.main.headings.barnPlural') }}, {{ item.sectionCount }}
                            {{ item.sectionCount === 1 ? $t('views.monitoring.main.headings.section') : $t('views.monitoring.main.headings.sectionPlural') }}
                        </div>
                    </div>
                </div>
                <div class="pr-1">
                    <button type="button" class="p-3 flex items-center justify-center rounded-full transition-colors hover:bg-gray-200/70 duration-200" @click="toggleExpandFarm(item.farm_id)">
                        <Icon class="h-4 w-4 transform transition-transform rotate-90 cursor-pointer" :class="{ 'rotate-[270deg]': isExpanded(item.farm_id) }" :src="IconSource.Right"></Icon>
                    </button>
                </div>
            </div>
            <ExpandCollapseTransition>
                <div v-if="isExpanded(item.farm_id)" class="mt-5 space-y-5">
                    <FoodBarnSection v-for="barn in item.barns" :key="barn.headers.barn_id" :barn="barn" />
                </div>
            </ExpandCollapseTransition>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Icon from '@/components/icons/Icon.vue';
import { MonitoringDataItem } from '../MonitoringTabs.vue';
import { IconSource } from '@/types';
import FoodBarnSection from './FoodBarnSection.vue';
import { MONITORING_STATE } from '../injectionKeys';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';

export default defineComponent({
    components: { Icon, FoodBarnSection, ExpandCollapseTransition },
    props: {
        items: {
            type: Array as () => MonitoringDataItem[],
            required: true,
        },
    },
    setup() {
        const { expandedFarms, toggleExpandFarm } = inject(MONITORING_STATE) as any;

        const isExpanded = (farmId: number) => expandedFarms.value.includes(farmId);

        return {
            IconSource,
            isExpanded,
            toggleExpandFarm,
        };
    },
});
</script>
